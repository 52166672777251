import * as React from 'react';
import { GlobalStyle } from '../../style/globalStyle';
// import "../css/main.css"
import SEO from './SEO';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <SEO
        title='Home'
        description='Web developer , Front End UI/UX developer, Development'
      />
      {children}
    </React.Fragment>
  );
};

export default Layout;
