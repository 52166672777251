import { createGlobalStyle } from 'styled-components';
// import { Device, Color } from './styledConstants';

export const GlobalStyle = createGlobalStyle`
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

}

body {
  color:#fff;
  font-size: 10px;
  font-family: "Saira Condensed", sans-serif;
  background-color: #1a191e;
  height:100%;
  /* overflow: hidden;  */
  &::selection {
    background: #ffb7b7; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: #ffb7b7; /* Gecko Browsers */
  }
  
}

#root {
  height: 100%;
}

p,a,h1,h3,span{
  &::selection {
    background: #ffb7b7; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: #ffb7b7; /* Gecko Browsers */
  }
}

a, a:active, a:link, a:visited, a:hover {
  /* cursor: pointer;
  text-decoration: none; */
}
`;
