import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`;

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query);
  const { author, siteDesc, image, siteUrl, siteTitle, twitterUsername } =
    site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{ lang: 'eng' }}
      title={`${siteTitle} | ${author}` || title}
    >
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='description' content={description || siteDesc} />
      <meta name='image' content={image} />
      <meta name='twitterUsername' content={twitterUsername} />
      <meta name='url' content={siteUrl} />
    </Helmet>
  );
};

export default SEO;
